footer {
    width: 100%;
    background-color: #000;

    nav {

        & > ul {
            text-align: center;
            list-style: none;
            margin: 0 1em;
            padding: 0;
            white-space: nowrap;

            & > li {
                display: inline-block;
                padding: 0;
                text-shadow: 0 0 4px #2e3842;

                & > a {
                    border: 0;
                    color: #aaa;
                    display: block;
                    font-size: 0.7em;
                    letter-spacing: 0.225em;
                    padding: 0 1.5em;
                    text-transform: uppercase;
                    font-weight: normal;
                }

            }

        }

    }

}