
em,
i {
    font-style: italic;
}

p {
    margin: 0 0 2em 0;
}

h1,
h2,
h3,
h4 {
    color: #000;
    font-weight: 800;
    letter-spacing: 0.17em;
    line-height: 1em;
    margin: 0 0 1em 0;
    text-transform: uppercase;
}

h1 a,
h2 a,
h3 a,
h4 a {
    color: inherit;
    text-decoration: none;
}

h2 {
    font-size: 1.35em;
    //line-height: 1.75em;
    line-height: 1.2em;
    padding-top: 1em;
    padding-bottom: 0em;
}

@media screen and (max-width: 736px) {
    h2 {
        font-size: 1.1em;
        //line-height: 1.65em;
    }
}

h3 {
    font-size: 1.15em;
    line-height: 1.75em;
}

@media screen and (max-width: 736px) {
    h3 {
        font-size: 1em;
        line-height: 1.65em;
    }
}



sub {
    font-size: 0.8em;
    position: relative;
    top: 0.5em;
}

sup {
    font-size: 0.8em;
    position: relative;
    top: -0.5em;
}

hr {
    border: 0;
    border-bottom: solid 2px #fff;
    margin: 3em 0;
}

hr.major {
    margin: 4.5em 0;
}

blockquote {
    border-left: solid 4px #fff;
    font-style: italic;
    margin: 0 0 2em 0;
    padding: 0.5em 0 0.5em 2em;
}

.align-left {
    text-align: left;
}

.align-center {
    text-align: center;
}

.align-right {
    text-align: right;
}






/* List */
ul {
    list-style: disc;
    margin: 0 0 2em 0;
    padding-left: 1em;
}

ul li {
    padding-left: 0.5em;
}

ul.alt {
    list-style: none;
    padding-left: 0;
}

ul.alt li {
    border-top: solid 1px #fff;
    padding: 0.5em 0;
}

ul.alt li:first-child {
    border-top: 0;
    padding-top: 0;
}

ul.icons {
    cursor: default;
    list-style: none;
    padding-left: 0;
}

ul.icons li {
    display: inline-block;
    padding: 0 1em 0 0;
}

ul.icons li:last-child {
    padding-right: 0 !important;
}

ul.icons.major {
    padding: 1em 0;
}

ul.icons.major li {
    padding-right: 3.5em;
}

@media screen and (max-width: 736px) {
    ul.icons.major li {
        padding: 0 1em !important;
    }
}

ul.actions {
    cursor: default;
    list-style: none;
    padding-left: 0;
}

ul.actions li {
    display: inline-block;
    padding: 0 1.5em 0 0;
    vertical-align: middle;
}

ul.actions li:last-child {
    padding-right: 0;
}

ul.actions.small li {
    padding: 0 0.75em 0 0;
}

ul.actions.vertical li {
    display: block;
    padding: 1.5em 0 0 0;
}

ul.actions.vertical li:first-child {
    padding-top: 0;
}

ul.actions.vertical li > * {
    margin-bottom: 0;
}

ul.actions.vertical.small li {
    padding: 0.75em 0 0 0;
}

ul.actions.vertical.small li:first-child {
    padding-top: 0;
}

ul.actions.fit {
    display: table;
    margin-left: -1.5em;
    padding: 0;
    table-layout: fixed;
    width: calc(100% + 1.5em);
}

ul.actions.fit li {
    display: table-cell;
    padding: 0 0 0 1.5em;
}

ul.actions.fit li > * {
    margin-bottom: 0;
}

ul.actions.fit.small {
    margin-left: -0.75em;
    width: calc(100% + 0.75em);
}

ul.actions.fit.small li {
    padding: 0 0 0 0.75em;
}

@media screen and (max-width: 736px) {
    ul.actions li {
        display: block;
        padding: 1em 0 0 0;
        text-align: center;
        width: 100%;
    }
    ul.actions li:first-child {
        padding-top: 0;
    }
    ul.actions li > * {
        margin: 0 auto !important;
        max-width: 30em;
        width: 100%;
    }
    ul.actions li > *.icon:before {
        margin-left: -1em;
    }
    ul.actions.small li {
        padding: 0.5em 0 0 0;
    }
    ul.actions.small li:first-child {
        padding-top: 0;
    }
}

dl {
    margin: 0 0 2em 0;
}

