.img-txt-list {
    // flexbox
    .box {
        width: 100%;
        display: flex;
    }
    .img {
        flex-basis: 38.2%;
        
        img {
            width: 100%;
        }
    }
    .desc {
        flex-basis: 61.8%;
        padding-left: 2em;
    }
    .img-txt {
        border-top: 2px solid #60f50d;
        padding: 1.3em 0;

        &:last-Child{
            border-bottom: 2px solid #60f50d;
            margin-bottom: 3em;
        }
    }

    // typo
    h3 {
        color: #52e400;
        text-transform: none;
        font-weight: bold;
        margin: 0.1em 0 0em 0;
        letter-spacing: .05em;
        line-height: 1.45em;
    }
    p {
        margin: 0;
        color: #444;
        &.subline {
            letter-spacing: 0.045em;
        }
    }


    @media screen and (max-width: 736px){

        .box {
            flex-direction: column;
        }
        .img {
            width: 100%;
            margin-bottom: .8em;
        }
        .desc {
            width: 100%;
            padding-left: 0;
        }
    
    }

}