
// TDS

#tds {
    width: 25vw;
    height: 25vw;
    position: absolute;
    left: 11vw;
    top: -8%;
    // background-image: url('/images/TdS_2020.svg');
    // background-image: url('/tds-teaser.svg');
    background-size: contain;
    background-repeat: no-repeat;
    border: none;
    opacity: .9;
    transition: opacity .2s ease;

    &:hover {
        opacity: 1;
    }

}

#tds-mobile {
    display: none;
}

.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}


// fewo
#fewo {
    width: 28vw;
    height: 27vw;
    position: absolute;
    left: 11vw;
    top: -7%;
    background-image: url('/images/HidSF_Stoerer.svg');
    background-size: contain;
    background-repeat: no-repeat;
    border: none;
    opacity: .9;
    transition: opacity .2s ease;

    &:hover {
        opacity: 1;
    }

}

#fewo-mobile {
    display: none;
}

.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

@media screen and (max-width: 736px) {

    #tds {
        display: none;
    }

    #tds-mobile{
        display: block;
    }
}







/* Banner */

#banner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: default;
    height: 100vh;
    min-height: 35em;
    overflow: hidden;
    position: relative;
    text-align: center;
}

#banner h2 {
    transform: scale(1);
    transition: transform 1.5s ease, opacity 2s ease;
    transition-delay: 1.5s;
    display: inline-block;
    font-size: 2.25em;
    font-weight: 100;
    opacity: 1;
    padding: 0.35em 1em;
    position: relative;
    z-index: 1;
    top: -6.5vh;
    text-shadow: 0 0 3px black;
}

#banner p {
    letter-spacing: 0.225em;
    text-transform: uppercase;
}

#banner p a {
    color: inherit;
    text-shadow: 0 0 3px black;
}

#banner .more {
    transition: transform 0.75s ease, opacity 0.75s ease;
    transition-delay: 3.5s;
    transform: translateY(0);
    border: none;
    bottom: 0;
    color: inherit;
    font-size: 0.8em;
    height: 8.5em;
    left: 50%;
    letter-spacing: 0.225em;
    margin-left: -8.5em;
    opacity: 1;
    outline: 0;
    padding-left: 0.225em;
    position: absolute;
    text-align: center;
    text-transform: uppercase;
    width: 16em;
    z-index: 1;
    text-shadow: 0 0 3px black;
}

#banner .more:after {
    background-image: url("/theme/sf2020/images/arrow.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    bottom: 4em;
    content: '';
    display: block;
    height: 1.5em;
    left: 50%;
    margin: 0 0 0 -0.75em;
    position: absolute;
    width: 1.5em;
}

#banner:after {
    pointer-events: none;
    transition: opacity 3s ease-in-out;
    content: '';
    background: #000;
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
}


.projekt-logo {
    width: 100%;
    margin-top: 2em;
    margin-bottom: 6em;
    text-align: right;

    img {
        width: 11em;
        min-width: 200px;
        display: inline-block;
    }

    a {
        border: none;
    }
}




@media screen and (max-width: 736px) {
    #banner {
        padding: 7em 3em 5em 3em;
        height: auto;
        min-height: 0;
    }
    #banner h2 {
        font-size: 1.25em;
    }
    #banner br {
        display: none;
    }
    #banner .more {
        display: none;
    }
}

body.is-loading #banner h2 {
    transform: scale(0.90);
    opacity: 0;
}

body.is-loading #banner h2:before,
body.is-loading #banner h2:after {
    width: 0;
}

body.is-loading #banner .more {
    transform: translateY(8.5em);
    opacity: 0;
}

body.is-loading #banner:after {
    opacity: 1;
}




