
/* Landing */

body.landing #page-wrapper {
    // background-image: url("../../images/Karte_1.jpg");
    background-attachment: fixed;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 0;
}

body.landing #banner {
    //background-image: url("../../images/1920/IBA_SommerfrischeMotive_Universal01.jpg"); // js: rotate images
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;


    // svg {
    //     stroke: #fff;
    //     stroke-width: 3;
    // }
}

.menu-btn{
    color: #a9a9a9;
    display: none;
    position: fixed;
    top: 1em;
    left: 1.2em;
    z-index: 10003;
    text-transform: uppercase;
    font-size: 1em;
    letter-spacing: 0.1em;
    text-decoration: none;
    border-bottom: none;
}
@media screen and (max-width: 736px){
    .menu-btn{
        display: block;
    }
    #header {
        display: none;
    }
}



// sommerfrische
.zero-bottom-spacing {
    margin-bottom: 0;
}

.sf-more {
    p {
        border-bottom: 1px dotted;
        display: inline-block;
    }

    &:after {
        top: 4px !important;
    }
}

p strong {
    color: #000;
}





    h3 {
        color: #000;
        line-height: 1.3em;
        letter-spacing: 0.14em;
        margin-bottom: 0.3em;
        margin-top: 3em;

        strong {
            color: #60f50d;
        }
    }

    p {
        font-size: 1.1em;
    }

    .tel {
        display: inline-block;
        width: 2.4em;
    }

    .no-bottom-spacing {
        margin-bottom: 0;
        padding-bottom: 0.7em;
    }



.maps-panel {

    .wrapper header {
        position: absolute;
    }

    img {
        width: 100vw;
    }

    img.detail {
        padding-top: 2em;
    }
}
@media screen and (max-width: 736px){
    img.europe {
        padding-top: 2em;
    }

}


.b-lazy {
    transition: opacity 500ms ease-in-out;
    max-width: 100%;
    opacity: 0;
}

.b-lazy.b-loaded {
    opacity: 1;
}


header h2 strong {
    color: #60f50d !important;
}

.img-wrap {
    //font-size: 0; // prevent space btw. images
}

img {
    display: block;
    // width: 100vw;
    // height: 75vw;
}

img.iba-logo {
    width: 10em;

    margin-bottom: 3em;
    margin-top: 3em;
}

h3 {
    //color: $highlight_color;
}


// break long urls
p {
    overflow-wrap: break-word;
    word-wrap: break-word;

    // -ms-word-break: break-all;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    // word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;

    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
}


#dates {

    .accordion {

    }

    .date-holder {
        border-top: 2px solid #60f50d;
        padding: 1.3em 0;

        &:last-Child{
            border-bottom: 2px solid #60f50d;
            margin-bottom: 3em;
        }
    }
    h3 {
        color: #000;
        text-transform: none;
        font-weight: bold;
        margin: 0.1em 0 0 0;
        letter-spacing: .05em;
            margin-bottom: -0.6em;
    }
    p {
        margin: 0;

        &.subline {
            letter-spacing: 0.045em;
        }
    }

    .description {
        padding: .7em 0;
    }

    .location {
        padding: .7em 0;
    }
}


.img-wrap img {
    width: 100vw;
    height: auto;
}

// landing
.panel0 {
    background-color: #000;
    color: #fff;

    h2, a, p {
        color: #fff;
    }
}


.text-panel {
    background-color: #fff;
    color: #000;

    p {
        color: #000;
        line-height: 1.65em;
    }


    // svg {
    //     stroke: #000;
    //     stroke-width: 3;
    // }

}

section.teaser {
    ul {
        list-style-type: none; 
        padding-left: 0;
        
        li {
            padding-left: 0;
            padding-bottom: .5em;
        }

    }
    a {
        font-weight: bold;
    }
}

.wrapper {
    padding: 3em 0 4em;
    &.alt {
        padding: 0;
    }

    > .inner {
        width: 60em;
        margin: 0 auto;
    }
}

section.wrapper.text-panel + section.wrapper.text-panel  {
    padding-top: 0em;
}
section.wrapper.text-panel.section-1  {
    padding-top: 6em !important;
}

@media screen and(max-width: 1280px) {
    .wrapper > .inner {
        width: 90%;
    }
}
@media screen and(max-width: 980px) {
    .wrapper {
        padding: 4em 2em 2em;
        > .inner {
            width: 100%;
        }
    }
}

section.subpage {
    height: 30vh; 
    min-height: 8em;
    
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
}

.subpage.large {
    height: 66vh; 
    min-height: 15em;
}

