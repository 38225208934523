
@import 'reset';
@import 'jquery.accordion';
@import 'pushy.scss';
@import 'footer.scss';
@import 'img-txt-list.scss';
@import 'events.scss';

/* Basic */


$background: #fff;
$highlight_color: #60f50d;


body {
    background: $background;
}

body.is-loading *,
body.is-loading *:before,
body.is-loading *:after {
    animation: none !important;
    transition: none !important;
}

body,
input,
select,
textarea {
    color: #000;
    font-family: Raleway, Helvetica, sans-serif;
    font-size: 15pt;
    font-weight: 200;
    letter-spacing: 0.075em;
    line-height: 1.65em;
}

@media screen and (max-width: 1680px) {
    body,
    input,
    select,
    textarea {
        font-size: 13pt;
    }
}

@media screen and (max-width: 1280px) {
    body,
    input,
    select,
    textarea {
        font-size: 12pt;
    }
}

@media screen and (max-width: 736px) {
    body,
    input,
    select,
    textarea {
        font-size: 11pt;
        letter-spacing: 0.0375em;
    }
}

a {
    transition: color 0.2s ease, border-bottom-color 0.2s ease;
    border-bottom: dotted 1px;
    color: inherit;
    text-decoration: none;
}

a:hover {
    border-bottom-color: transparent;
}

strong,
b {
    // color: #fff;
    
    font-weight: 600;
}


@import 'typo.scss';

/* Section/Article */

header p {
    color: rgba(255, 255, 255, 0.5);
    position: relative;
    top: -0.25em;
}

header h3 + p {
    font-size: 1.1em;
}

header h4 + p,
header h5 + p,
header h6 + p {
    font-size: 0.9em;
}

header.major {
    margin: 0 0 2.5em 0;
}

header.major h2,
header.major h3,
header.major h4,
header.major h5,
header.major h6 {
    //border-bottom: solid 2px #fff;
    //display: inline-block;
    padding-bottom: 0em;
    position: relative;
}

header.major h2:after,
header.major h3:after,
header.major h4:after,
header.major h5:after,
header.major h6:after {
    content: '';
    display: block;
    height: 1px;
}

header.major p {
    top: 0;
}

@media screen and (max-width: 736px) {
    header.major {
        margin: 0 0 2em 0;
    }
}

@media screen and (max-width: 980px) {
    header br {
        //display: none;
    }
}


/* Icon */

.icon {
    text-decoration: none;
    border-bottom: none;
    position: relative;
}

.icon:before {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-transform: none !important;
}

.icon > .label {
    display: none;
}

.icon.major {
    transform: rotate(-45deg);
    border-radius: 3px;
    border: solid 2px #fff;
    display: inline-block;
    font-size: 1.35em;
    height: calc(3em + 2px);
    line-height: 3em;
    text-align: center;
    width: calc(3em + 2px);
}

.icon.major:before {
    transform: rotate(45deg);
    display: inline-block;
    font-size: 1.5em;
}

@media screen and (max-width: 736px) {
    .icon.major {
        font-size: 1em;
    }
}



/* Image */

.image {
    border-radius: 3px;
    border: 0;
    display: inline-block;
    position: relative;
}

.image img {
    border-radius: 3px;
    display: block;
}

.image.left {
    float: left;
    margin: 0 2em 2em 0;
    top: 0.25em;
}

.image.right {
    float: right;
    margin: 0 0 2em 2em;
    top: 0.25em;
}

.image.left,
.image.right {
    max-width: 40%;
}

.image.left img,
.image.right img {
    width: 100%;
}

.image.fit {
    display: block;
    margin: 0 0 2em 0;
    width: 100%;
}

.image.fit img {
    width: 100%;
}


@import 'sections.scss';



/* Page Wrapper + Menu */

#page-wrapper {
    transition: opacity 0.5s ease-out;
    opacity: 1;
    padding-top: 3em;
}

#page-wrapper:before {
    background: transparent;
    content: '';
    display: block;
    display: none;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10001;
}


/* Header */

#header {
    transition: background-color 0.2s ease;
    background: #2e3842;
    height: 3em;
    left: 0;
    line-height: 3em;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10000;
}

#header h1 {
    transition: opacity 1.8s ease;
    height: inherit;
    left: 1.25em;
    line-height: inherit;
    position: absolute;
    top: 0;
}

#header h1 a {
    border: 0;
    display: block;
    height: inherit;
    line-height: inherit;
    color: #fff;
}

@media screen and (max-width: 736px) {
    #header h1 a {
        font-size: 0.8em;
    }
}

.is-loading #header nav {
    opacity: 0;
}

#header nav {
    transition: opacity 1.5s ease;
    transition-delay: 4.5s;
    height: inherit;
    line-height: inherit;
    position: absolute;
    right: 0;
    top: 0;
}

#header nav > ul {
    list-style: none;
    margin: 0;
    padding: 0;
    white-space: nowrap;
}

#header nav > ul > li {
    display: inline-block;
    padding: 0;
    text-shadow: 0 0 4px #2e3842;
}

#header nav > ul > li > a {
    border: 0;
    color: #fff;
    display: block;
    font-size: 0.8em;
    letter-spacing: 0.225em;
    padding: 0 1.5em;
    text-transform: uppercase;
    font-weight: 700;
}


@media screen and (max-width: 736px) {
    #header nav > ul > li > a {
        padding: 0 0 0 1.5em;
    }
}

#header nav > ul > li:first-child {
    margin-left: 0;
}

#header.alt {
    background: transparent;
}

#header.alt h1 {
    pointer-events: none;
    opacity: 0;
}



/* Main */
/*
#main > header {
    padding: 12em 0 10em 0;
    background-image: url("../../images/Karte_1.jpg");
    background-attachment: fixed;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
}

#main > header h2 {
    font-size: 1.75em;
    margin: 0 0 0.5em 0;
}

#main > header p {
    color: inherit;
    letter-spacing: 0.225em;
    text-transform: uppercase;
    top: 0;
}

#main > header p a {
    color: inherit;
}

@media screen and (max-width: 1680px) {
    #main > header {
        padding: 10em 0 8em 0;
    }
}

@media screen and (max-width: 1280px) {
    #main > header {
        padding: 8em 3em 6em 3em;
    }
}

@media screen and (max-width: 980px) {
    #main > header {
        padding: 10em 3em 8em 3em;
    }
}

@media screen and (max-width: 736px) {
    #main > header {
        padding: 5em 3em 3em 3em;
    }
    #main > header h2 {
        font-size: 1.25em;
        margin: 0 0 1em 0;
    }
}
*/


body.is-mobile #main > header {
    background-attachment: scroll;
}


@import 'banner.scss';


/* Footer */

#footer {
    padding: 1em 0 1em 0;
    background-color: #1d242a;
    text-align: center;
}

#footer .icons {
    font-size: 1.25em;
}

#footer .icons a {
    color: rgba(255, 255, 255, 0.5);
}

#footer .icons a:hover {
    color: #fff;
}

#footer .copyright {
    color: rgba(255, 255, 255, 0.5);
    font-size: 0.8em;
    letter-spacing: 0.225em;
    list-style: none;
    padding: 0;
    text-transform: uppercase;
}

#footer .copyright li {
    border-left: solid 1px rgba(255, 255, 255, 0.5);
    display: inline-block;
    line-height: 1em;
    margin-left: 1em;
    padding-left: 1em;
}

#footer .copyright li:first-child {
    border-left: 0;
    margin-left: 0;
    padding-left: 0;
}

#footer .copyright li a {
    color: inherit;
}

#footer .copyright li a:hover {
    color: #fff;
}

@media screen and (max-width: 480px) {
    #footer .copyright li {
        border: 0;
        display: block;
        line-height: 1.65em;
        margin: 0;
        padding: 0.5em 0;
    }
}

@media screen and (max-width: 980px) {
    #footer {
        padding: 1em 3em 1em 3em;
    }
}

@media screen and (max-width: 736px) {
    #footer {
        padding: 1em 2em 1em 2em;
    }
}






figure.img-wrap figcaption {
    position: relative;
    bottom: 2rem;
    left: 1rem;
    color: #fff;
    text-shadow: 0 0 5px #000;
}


#page-wrapper > hr {
    border-bottom: 4px solid $highlight_color;
    margin-bottom: 4.5em;
}
